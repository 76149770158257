import React, { useState } from 'react';
import { Box, Container, Grid, Stack, Typography, Link, Modal, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import logo from '@assets/logos/logo.png';
import antware from '@assets/logos/antware.png';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TextButton from '@components/Utilities/Buttons/TextButton';

const Footer: React.FunctionComponent = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        bgcolor: 'background.paper',
        border: 'transparent',
        boxShadow: 0,
        p: 4,
        maxHeight: '90vh', // Ajusta según tus necesidades
        overflowY: 'auto',
        borderRadius: 3
    };
    const scrollStyle = {
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
          borderRadius: '8px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#2c88cd',
          borderRadius: '8px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555'
        }
      };
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Box component="footer" p={4} bgcolor="#E7E7E7">
            <Container>
                <Grid container spacing={4}> 
                    <Grid item xs={12}>
                        <Stack spacing={2} sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}>
                            <Box component={NavLink} to="/">
                                <Box component="img" src={logo} alt="logo" height={50} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={2} sx={{ alignItems: { xs: 'center', md: 'flex-start' } }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <PlaceIcon />
                                <Typography variant="subtitle1">11 Morrow Rd, Barrie, ON L4N 3V7</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <EmailIcon />
                                <Typography variant="subtitle1">orders@pspfab.com</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <PhoneIcon />
                                <Typography variant="subtitle1">705-797-1320</Typography>
                            </Stack>
                           
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack
                            spacing={2}
                            sx={{
                                display: 'flex',
                                alignItems: { xs: 'center', md: 'flex-start' }
                            }}
                        >
                            <Typography variant="subtitle1" fontWeight={600}>
                                Support
                            </Typography>
                            <TextButton to="/#contact">
                                <Typography variant="subtitle1">Contact Us</Typography>
                            </TextButton>
                            <Typography variant="subtitle1" onClick={handleOpen} style={{ cursor: 'pointer' }}
                                onMouseEnter={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.color = '#2596be';
                                }}
                                onMouseLeave={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.color = '';
                                }}
                            >Privacy Policy</Typography>
                            <TextButton to="/warrantyRegistrationForm">
                                <Typography variant="subtitle1">Warranty Registration</Typography>
                            </TextButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack
                            spacing={2}
                            sx={{
                                display: 'flex',
                                alignItems: { xs: 'center', md: 'flex-start' }
                            }}
                        >
                            <Typography variant="subtitle1" fontWeight={600}>
                                Products
                            </Typography>
                            <TextButton to="/safetyCoverOverview">
                                <Typography variant="subtitle1">Safety Covers</Typography>
                            </TextButton>
                            <TextButton to="/spaCoverOverview">
                                <Typography variant="subtitle1">Spa Covers</Typography>
                            </TextButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2">
                                    Copyright © 2023 PSP Fabricating Inc. All rights reserved.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Box component={Link} href="https://antware.mx/" target="_blank">
                                    <Box component="img" src={antware} alt="antware" height={40} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ ...style, ...scrollStyle }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Privacy Policy
                        </Typography>

                        <Typography variant="subtitle1" component="h3"  sx={{ mt: 2 }}>
                            Effective Date: July 17, 2024
                        </Typography>

                        <Typography variant="subtitle1" component="h3" sx={{ mt: 2 }}>
                            1. Introduction
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            PSP Fabricating (“we,” “us,” “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website pspfabricating.com, use our services, or interact with us through 10DLC (10-Digit Long Code) messaging. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access our services.
                        </Typography>

                        <Typography variant="subtitle1" component="h3" sx={{ mt: 2 }}>
                            2. Information We Collect
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            <strong>Personal Data</strong><br />
                            We may collect personally identifiable information, such as your name, email address, phone number, and any other information you voluntarily provide to us when you:
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, ml: 2 }}>
                            • Register or use our services.<br />
                            • Subscribe to our newsletter.<br />
                            • Participate in surveys, promotions, or contests.<br />
                            • Contact us for support or inquiries.
                        </Typography>

                        <Typography variant="body2" sx={{ mt: 1 }}>
                            <strong>Usage Data</strong><br />
                            We may also collect information that your browser sends whenever you visit our website or use our services, such as your computer’s Internet Protocol (IP) address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
                        </Typography>

                        <Typography variant="subtitle1" component="h3" sx={{ mt: 2 }}>
                            3. How We Use Your Information
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            We use the information we collect in the following ways:
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, ml: 2 }}>
                            • To provide, operate, and maintain our services.<br />
                            • To improve, personalize, and expand our services.<br />
                            • To understand and analyze how you use our services.<br />
                            • To develop new products, services, and features.<br />
                            • To communicate with you, either directly.
                        </Typography>

                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Note any phone numbers we collect will not be shared with a third party.
                        </Typography>

                        <Button onClick={handleClose} variant="contained" color="secondary" sx={{ mt: 2, marginLeft: 'auto', marginRight: 'auto', display: 'block' }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            </Container>
        </Box>
    );
};

export default Footer;
